import React, { useState } from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import system from '@rebass/components'
import { Text as BaseText } from 'rebass'
import { default as BaseTextField } from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import { default as BaseInputLabel } from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { default as BaseRadio } from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import { Loading, Layout, ContentWithSideMenu, FileUpload } from 'components'
import { colors, fonts } from 'theme'

const states = Object.entries(require('../utils/states.json'))

const TextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.lightTeal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.lightTeal,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.lightTeal,
      },
      '&:hover fieldset': {
        borderColor: colors.lightTeal,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.lightTeal,
      },
    },
  },
})(BaseTextField)

const SubmitButton = withStyles({
  root: {
    backgroundColor: colors.darkTeal,
    borderColor: colors.darkTeal,
    '&:hover': {
      backgroundColor: colors.lightTeal,
      borderColor: colors.lightTeal,
    },
    '&:active': {
      boxShadow: 'None',
      backgroundColor: colors.lightTeal,
      borderColor: colors.lightTeal,
    },
  },
})(Button)

const Radio = withStyles({
  root: {
    color: colors.darkTeal,
    '&$checked': {
      color: colors.lightTeal,
    },
  },
  checked: {},
})((props) => <BaseRadio color="default" {...props} />)

const InputLabel = withStyles({
  root: {
    '&$focused': {
      color: colors.lightTeal,
    },
  },
  focused: {
    '&$focused': {
      color: colors.lightTeal,
    },
  },
})(BaseInputLabel)

const RadioFormLabel = withStyles({
  root: {
    color: colors.darkTeal,
    '&$focused': {
      color: colors.lightTeal,
    },
  },
  focused: {
    '&$focused': {
      color: colors.lightTeal,
    },
  },
})(FormLabel)

const styles = () => ({
  underline: {
    borderBottom: `2px solid ${colors.white}`,
    '&:after': {
      borderBottom: `2px solid ${colors.lightTeal}`,
    },
  },
})

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

export const query = graphql`
  query careersQuery {
    prismic {
      menu(uid: "about", lang: "en-us") {
        title
        items {
          name
          show_in_side_menu
          link {
            _linkType
            __typename
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`

const Text = system(
  {
    extend: BaseText,
    fontFamily: fonts.main,
    fontSize: '15px',
  },
  {
    letterSpacing: '.075em',
  },
)

const Careers = ({
  classes,
  data: {
    prismic: { menu },
  },
}) => {
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState(false)
  const [craft, setCraft] = useState('')
  const [craftError, setCraftError] = useState(false)
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [safetyCouncilCard, setSafetyCouncilCard] = useState('')
  const [safetyCouncilCardError, setSafetyCouncilCardError] = useState(false)
  const [securityPassport, setSecurityPassport] = useState('')
  const [securityPassportError, setSecurityPassportError] = useState(false)
  const [state, setState] = useState('')
  const [stateError, setStateError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [twic, setTwic] = useState('')
  const [twicError, setTwicError] = useState(false)
  const [yoe, setYoe] = useState('')
  const [yoeError, setYoeError] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const [file, setFile] = useState(null)

  const resetErrors = () => {
    setCityError(false)
    setCraftError(false)
    setError(null)
    setFirstNameError(false)
    setLastNameError(false)
    setPhoneError(false)
    setSafetyCouncilCardError(false)
    setSecurityPassportError(false)
    setStateError(false)
    setTwicError(false)
    setYoeError(false)
  }

  const setErrors = () => {
    let hasErrors = false

    if (isEmpty(firstName)) {
      setFirstNameError(true)
      hasErrors = true
    }
    if (isEmpty(lastName)) {
      setLastNameError(true)
      hasErrors = true
    }
    if (isEmpty(craft)) {
      setCraftError(true)
      hasErrors = true
    }
    if (isEmpty(yoe)) {
      setYoeError(true)
      hasErrors = true
    }
    if (isEmpty(phone)) {
      setPhoneError(true)
      hasErrors = true
    }
    if (isEmpty(city)) {
      setCityError(true)
      hasErrors = true
    }
    if (isEmpty(state)) {
      setStateError(true)
      hasErrors = true
    }
    if (isEmpty(twic)) {
      setTwicError(true)
      hasErrors = true
    }
    if (isEmpty(securityPassport)) {
      setSecurityPassportError(true)
      hasErrors = true
    }
    if (isEmpty(safetyCouncilCard)) {
      setSafetyCouncilCardError(true)
      hasErrors = true
    }

    return hasErrors
  }

  const onSubmit = async (e) => {
    if (e && e.preventDefault) e.preventDefault()

    resetErrors()

    if (setErrors()) {
      return
    }

    // const url =
    //   'https://wt-8fb331d9f68aed90448cc3965dd7e540-0.sandbox.auth0-extend.com/careers-form-submission'
    const url = '../.netlify/functions/career-form'

    setLoading(true)

    try {
      await axios({
        method: 'post',
        url,
        data: {
          city,
          craft,
          firstName,
          lastName,
          phone: phone.match(/\d+/g).join(''),
          safetyCouncilCard,
          securityPassport,
          state,
          twic,
          yoe,
          resume: file ? file.url : null,
        },
        headers: { 'Content-Type': 'application/json' },
      })

      setSuccess(true)
      setLoading(false)
    } catch (e) {
      setError(e.message)
      setSuccess(false)
      setLoading(false)
    }
  }

  const closePicker = () => {
    setShowPicker(false)
  }

  const onUploadSuccess = (res) => {
    const file = res.filesUploaded[0]

    setFile(file)
  }

  const mb = 30

  return (
    <>
      <Layout>
        {/*<Hero data={service} />*/}
        <ContentWithSideMenu menu={menu} uid="careers">
          {success ? (
            <Text>
              Thank you. The form has been successfully submitted. If the information provided
              matches any of our available positions, a member of the Catamaran Administration team
              will contact you.
            </Text>
          ) : null}
          {!success ? (
            <>
              <form style={{ marginTop: 20, maxWidth: 800 }} onSubmit={onSubmit}>
                <Text>
                  Interested in becoming part of the Catamaran team? Complete the information below
                  to be considered for positions throughout the Americas.
                </Text>
                <div style={{ marginTop: mb, display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    required
                    error={firstNameError}
                    style={{ marginBottom: mb }}
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                      setFirstNameError(false)
                    }}
                  />
                  <TextField
                    required
                    error={lastNameError}
                    style={{ marginBottom: mb }}
                    id="lastName"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                      setLastNameError(false)
                    }}
                  />
                  <TextField
                    required
                    error={craftError}
                    style={{ marginBottom: mb }}
                    id="craft"
                    label="Craft"
                    value={craft}
                    onChange={(e) => {
                      setCraft(e.target.value)
                      setCraftError(false)
                    }}
                  />
                  <TextField
                    required
                    error={yoeError}
                    type="number"
                    style={{ marginBottom: mb }}
                    id="yoe"
                    label="Years of Experience"
                    value={yoe}
                    onChange={(e) => {
                      setYoe(e.target.value)
                      setYoeError(false)
                    }}
                  />
                  <TextField
                    required
                    error={phoneError}
                    style={{ marginBottom: mb }}
                    id="phone"
                    label="Phone Number"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value)
                      setPhoneError(false)
                    }}
                  />
                  <TextField
                    required
                    error={cityError}
                    style={{ marginBottom: mb }}
                    id="city"
                    label="City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value)
                      setCityError(false)
                    }}
                  />
                  <FormControl style={{ marginBottom: mb }}>
                    <InputLabel htmlFor="age-simple">State</InputLabel>
                    <Select
                      required
                      error={stateError}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      input={
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                        />
                      }
                      inputProps={{
                        name: 'state',
                        id: 'state',
                      }}>
                      <MenuItem value="">
                        <em>Select One...</em>
                      </MenuItem>
                      {states.map(([key, value]) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <RadioContainer>
                    <FormControl component="fieldset" style={{ marginBottom: mb }}>
                      <RadioFormLabel error={twicError}>TWIC *</RadioFormLabel>
                      <RadioGroup
                        aria-label="TWIC"
                        name="twic"
                        value={twic}
                        onChange={(e) => setTwic(e.target.value)}>
                        <div style={{ display: 'flex ' }}>
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ marginBottom: mb }}>
                      <RadioFormLabel error={securityPassportError}>
                        Security Passport *
                      </RadioFormLabel>
                      <RadioGroup
                        aria-label="Security Passport"
                        name="securityPassport"
                        value={securityPassport}
                        onChange={(e) => setSecurityPassport(e.target.value)}>
                        <div style={{ display: 'flex ' }}>
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ marginBottom: mb }}>
                      <RadioFormLabel error={safetyCouncilCardError}>
                        Safety Council Card *
                      </RadioFormLabel>
                      <RadioGroup
                        aria-label="Safety Council Card"
                        name="safetyCouncilCard"
                        value={safetyCouncilCard}
                        onChange={(e) => setSafetyCouncilCard(e.target.value)}>
                        <div style={{ display: 'flex ' }}>
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </RadioContainer>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        Resume:
                        {file ? (
                          <a href={file.url} target="_blank">
                            <span>{` ${file.filename}`}</span>
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                      {file ? (
                        <button
                          style={{
                            color: 'red',
                            backgroundColor: 'transparent',
                            border: 'none',
                            marginLeft: 10,
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            setFile(null)
                          }}>
                          Remove
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPicker(true)
                        }}>
                        Upload
                      </button>
                    </div>
                  </div>
                  <SubmitButton
                    disabled={loading}
                    onClick={onSubmit}
                    style={{ height: '40px' }}
                    variant="contained"
                    color="primary">
                    {loading ? <Loading /> : 'Submit'}
                  </SubmitButton>
                  {error ? (
                    <div style={{ marginTop: mb, color: 'red', textAlign: 'center' }}>{error}</div>
                  ) : null}
                  <div style={{ fontSize: '14px', marginTop: 30 }}>
                    <Text>
                      Catamaran Solutions is an Equal Employment Opportunity Employer. All qualified
                      applicants will receive consideration for employment, without regard to sex,
                      gender identity, sexual orientation, race, color, religion, national origin,
                      disability, protected veteran status, age, or any other characteristic
                      protected by law.
                    </Text>
                  </div>
                </div>
              </form>
            </>
          ) : null}
        </ContentWithSideMenu>
      </Layout>
      {showPicker && (
        <FileUpload
          maxFiles={1}
          onSuccess={onUploadSuccess}
          onError={closePicker}
          onClose={closePicker}
          onCancel={closePicker}
        />
      )}
    </>
  )
}

Careers.query = query

export default withStyles(styles)(Careers)
